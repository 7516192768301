import React, { useState } from "react";
import CookieConsent, { getCookieConsentValue } from "react-cookie-consent";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import AspectRatioRoundedIcon from "@material-ui/icons/AspectRatioRounded";
import AlternateEmailRoundedIcon from "@material-ui/icons/AlternateEmailRounded";
import StorageRoundedIcon from "@material-ui/icons/StorageRounded";
import DoubleArrowRoundedIcon from "@material-ui/icons/DoubleArrowRounded";
import {
  BrowserView,
  MobileView,
} from "react-device-detect";

export const CookieNoticeBanner = () => {
  const [open, setOpen] = useState(true);

  const cookieName = "rgPolicyAccepted";

  const handleClose = () => {
    setOpen(false);
    setCookie(cookieName, String(true), 365);
  };

  function setCookie(name, value, days) {
    var expires = "";
    if (days) {
      var date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
  }

  const cookie = getCookieConsentValue(cookieName);

  const desktopBanner = (
    <CookieConsent
      location="bottom"
      style={{ textAlign: "left" }}
      buttonText="Ok, rozumiem"
      buttonStyle={{ borderRadius: "8px" }}
      expires={365}
      cookieName={cookieName}
    >
      Strona używa ciasteczek w celu zapewnienia jak najwyższej jakości usługi.
      Kontynując przeglądanie akceptujesz naszą{" "}
      <a href="/polityka-prywatnosci" style={{ color: "white" }}>
        Politykę prywatności
      </a>
      .
    </CookieConsent>
  );

  const mobileDialog = (
    <div>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle id="customized-dialog-title">
          Drogi użytkowniku!
        </DialogTitle>
        <DialogContent dividers>
          <Typography variant="caption" gutterBottom>
            Strona używa ciasteczek w celu zapewnienia jak najwyższej jakości
            usługi. Kontynując przeglądanie akceptujesz naszą Politykę
            prywatności:
            <br />
            <div>
              <br />
              <React.Fragment>
                <Typography
                  align="left"
                  variant="subtitle1"
                  color="textPrimary"
                  style={{ fontWeight: "bold" }}
                >
                  <DoubleArrowRoundedIcon style={{ fontSize: "1.0rem" }} /> Kim
                  jesteśmy?
                </Typography>
                <p>
                  Adres naszej strony internetowej to:{" "}
                  <a href="/">rockgaweda.pl</a>. Używamy technologii, takich jak
                  pliki cookie, w celu umożliwienia zbierania informacji o
                  sposobie korzystania ze strony internetowej i jesteśmy
                  jednocześnie Administratorem Twoich Danych Osobowych.
                </p>
              </React.Fragment>

              <br />
              <React.Fragment>
                <Typography
                  variant="subtitle1"
                  color="textPrimary"
                  style={{ fontWeight: "bold" }}
                >
                  <DoubleArrowRoundedIcon style={{ fontSize: "1.0rem" }} />{" "}
                  Jakie dane zbieramy?
                </Typography>
                <div>
                  <br />
                  <Typography variant="caption" color="textPrimary">
                    <StorageRoundedIcon style={{ fontSize: "1.0rem" }} />{" "}
                    <b>Ciasteczka:</b> <br /> Pliki cookies stanowią dane
                    informatyczne, przechowywane na urządzeniu końcowym
                    użytkownika. Wykorzystywane są w celu umożliwienia zbierania
                    informacji o sposobie korzystania ze strony internetowej.
                    Użytkownik zawsze ma możliwość usunięcia plików cookies
                    i/lub zablokowania ich zapamiętywania. Ustawienia takie
                    oferuje każda przeglądarka internetowa.
                  </Typography>
                  <br />
                  <br />
                  <Typography variant="caption" color="textPrimary">
                    <AspectRatioRoundedIcon style={{ fontSize: "1.0rem" }} />{" "}
                    <b>Rozdzielczość ekranu: </b> <br /> Dane te są
                    wykorzystywane do optymalizacji działania strony.
                  </Typography>
                  <br />
                  <br />
                  <Typography variant="caption" color="textPrimary">
                    <AlternateEmailRoundedIcon style={{ fontSize: "1.0rem" }} />{" "}
                    <b>Opcjonalny adres email:</b> <br /> Może być podawany
                    opcjonalnie gdy użytkownik dodaje nową historię do bazy
                    danych. Wykorzystywany jest w celach kontaktowych,
                    dotyczącej dodanej historii przez autora.
                  </Typography>

                  <p>
                    Podanie dowolnych danych osobowych jest zawsze dobrowolne. W
                    każdej chwili masz prawo do aktualizacji lub całkowitego
                    usunięcia swoich danych osobowych. W tym celu należy
                    skontaktować się z administratorem strony{" "}
                    <a href="mailto:kontakt.rockgaweda@gmail.com">
                      kontakt.rockgaweda@gmail.com
                    </a>
                    .
                  </p>
                </div>
              </React.Fragment>
            </div>
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={handleClose}
            style={{
              backgroundColor: "rgb(255, 212, 45)",
              borderRadius: "8px"
            }}
          >
            Ok, rozumiem
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );

  if (cookie && cookie === String(true)) {
    return null;
  }

  return (
    <React.Fragment data-nosnippet>
      <BrowserView>{desktopBanner}</BrowserView>
      <MobileView>{mobileDialog}</MobileView>
    </React.Fragment>
  );
};
